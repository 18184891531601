<template>
  <div>
    <v-form @submit.prevent="handleSubmit" ref="form">
      <v-row>
        <v-card-title :style="{ color: $vuetify.theme.themes.light.primary }">
          {{ $t("tag.log.journal.entryTitle") }}
        </v-card-title>
        <v-col cols="12" class="d-flex flex-column pa-4">
          <CreateUpdate
            :comment.sync="journalEntry.comment"
            :preview-files="previewFiles"
            @file-input="handleFileInput"
            @remove-file="removeFile"
          />
          <v-btn color="primary" @click="addEntry" class="mt-4" block>
            {{ $t("tag.log.journal.journalEntry") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="my-4"></v-divider>
    <v-card-title
      v-if="journalEntries.length > 0"
      :style="{ color: $vuetify.theme.themes.light.primary }"
      >{{ $t("tag.log.journal.journalEntries") }}
    </v-card-title>

    <v-expansion-panels>
      <v-expansion-panel
        v-for="entry in journalEntriesPaged"
        :key="entry.tagJournalId"
        @change="handleExpansionChange(entry.tagJournalId)"
      >
        <v-expansion-panel-header hide-actions class="pa-0 mb-4">
          <template v-slot:default="{ open }">
            <v-col cols="12" class="pa-0">
              <v-card class="custom-border1">
                <v-card-title
                  class="subtitle-1 font-weight-bold light-blue lighten-5 py-2 black--text"
                >
                  <div class="d-flex flex-column align-start">
                    <div class="d-flex align-items-center">
                      <v-icon left small color="blue darken-2"
                        >mdi-account</v-icon
                      >
                      <span>{{ entry.fullName }}</span>
                    </div>
                    <div class="d-flex align-items-center mt-1">
                      <v-icon left small color="grey">mdi-clock-outline</v-icon>
                      <span class="caption grey--text ml-1">
                        {{
                          new Date(entry.updatedAt) > new Date(entry.createdAt)
                            ? `${$t("tag.log.journal.updatedAt")}: ${new Date(
                                entry.updatedAt
                              ).toLocaleString()}`
                            : `${$t("tag.log.journal.createdAt")}: ${new Date(
                                entry.createdAt
                              ).toLocaleString()}`
                        }}
                      </span>
                    </div>
                  </div>
                </v-card-title>

                <v-card-text class="pt-2">
                  <v-chip
                    small
                    color="blue lighten-4 black--text"
                    v-if="entry.comment"
                    class="custom-chip"
                  >
                    <v-icon left x-small>mdi-text</v-icon>
                    {{ $t("tag.log.journal.comment") }}
                  </v-chip>
                  {{ entry.comment }}

                  <v-row class="mt-4" no-gutters justify="space-between">
                    <v-col cols="auto">
                      <v-chip
                        v-if="entry.fileCount > 0"
                        small
                        color="green lighten-4 black--text"
                        class="custom-chip"
                      >
                        <v-icon left x-small>mdi-file</v-icon>
                        {{ entry.fileCount }}
                        {{
                          entry.fileCount > 1
                            ? $t("tag.log.journal.files")
                            : $t("tag.log.journal.file")
                        }}
                      </v-chip>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon
                        right
                        big
                        color="grey"
                        :class="{ 'rotate-down': open, 'rotate-up': !open }"
                      >
                        {{
                          open ? "mdi-arrow-up-thick" : "mdi-arrow-down-thick"
                        }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-card-text>
            <v-row
              v-if="journalEntryFiles.length > 0"
              class="d-flex flex-wrap justify-center"
            >
              <v-col
                v-for="file in journalEntryFiles"
                :key="file.file"
                class="d-flex flex-column align-items-center"
                cols="auto"
              >
                <template>
                  <div class="file-container">
                    <!-- Image Preview -->
                    <v-img
                      v-if="file.type.includes('image')"
                      :src="file.src"
                      width="100"
                      height="100"
                    ></v-img>

                    <!-- PDF Preview -->
                    <v-card
                      v-else-if="file.type.includes('pdf')"
                      width="100"
                      height="100"
                    >
                      <embed
                        :src="file.src"
                        type="application/pdf"
                        width="100"
                        height="100"
                      />
                    </v-card>

                    <!-- File Icon -->
                    <v-icon
                      v-else
                      :class="`file-icon mdi mdi-${getFileIcon(
                        file.extension
                      )}`"
                      width="100"
                      height="100"
                      :style="{
                        color: getFileIconColor(file.extension),
                      }"
                    ></v-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          color="white"
                          v-bind="attrs"
                          v-on="on"
                          @click="downloadFile(file)"
                          class="file-download-btn"
                        >
                          <v-icon small>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.download") }}</span>
                    </v-tooltip>
                  </div>
                </template>

                <!-- File Type Label -->
                <div class="file-label">
                  {{ file.extension.toUpperCase() }}
                </div>
              </v-col>
            </v-row>
            <v-row v-else class="d-flex justify-center">
              <v-col cols="12">
                <v-alert
                  type="info"
                  color="primary"
                  outlined
                  class="d-flex align-center justify-center"
                >
                  {{ $t("tag.log.journal.alert") }}
                </v-alert>
              </v-col>
            </v-row>
            <!-- New section for Edit and Delete icons -->
            <template>
              <v-row justify="end" class="mt-2">
                <v-btn icon color="primary" @click="openEditDialog(entry)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>{{ $t("common.edit") }}</span>
                  </v-tooltip>
                </v-btn>
                <v-btn icon color="error" @click="confirmDelete(entry)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                    </template>
                    <span>{{ $t("common.delete") }}</span>
                  </v-tooltip>
                </v-btn>
              </v-row>
            </template>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">
          {{ $t("common.delete") }}
        </v-card-title>
        <v-card-text>
          {{ $t("common.verify") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="deleteEntry">
            {{ $t("common.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Entry Dialog -->
    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <v-card-title
          class="headline"
          :style="{ color: $vuetify.theme.themes.light.primary }"
        >
          {{ $t("tag.log.journal.updateEntry") }}
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <CreateUpdate
              :comment.sync="editJournalEntry.comment"
              :preview-files="editPreviewFiles"
              @file-input="handleEditFileInput"
              @remove-file="removeEditFile"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeEditDialog">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="updateEntry">
            {{ $t("common.update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-pagination
      v-model="journalEntriesPage"
      :length="totalJournalPages"
      :total-visible="7"
      class="mt-4"
    ></v-pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { saveAs } from "file-saver";
import tagJournalRepository from "../../api/repositories/tagJournalRepository";
import CreateUpdate from "@/components/tagJournal/CreateUpdate";
import FileRepository from "@/api/repositories/fileRepository.js";
import ImageRepository from "@/api/repositories/imageRepository.js";
import i18n from "@/plugins/i18n";

export default {
  name: "TagJournal",
  props: {
    value: {
      default: undefined,
    },
  },

  components: {
    CreateUpdate,
  },

  data() {
    return {
      journalEntry: {
        comment: "",
        files: [],
      },
      journalEntries: [],
      journalEntryFiles: [],
      previewFiles: [],
      journalEntriesPage: 1,
      journalEntriesPerPage: 5,
      dialog: false,
      entryToDelete: null,
      editDialog: false,
      editJournalEntry: {
        comment: "",
        files: [],
      },
      editPreviewFiles: [],
      currentEntryId: null,
      tagJournalId: null,
      tempEditEntry: null,
      originalEditEntry: null,
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapGetters("users", ["user"]),

    journalEntriesPaged() {
      return this.journalEntries.slice(
        (this.journalEntriesPage - 1) * this.journalEntriesPerPage,
        (this.journalEntriesPage - 1) * this.journalEntriesPerPage +
          this.journalEntriesPerPage
      );
    },

    totalJournalPages() {
      return Math.ceil(this.journalEntries.length / this.journalEntriesPerPage);
    },
  },

  methods: {
    ...mapActions("tagJournal", {
      createJournal: "createJournal",
      getJournalsForTag: "getJournalsForTag",
      deleteJournal: "deleteJournal",
      updateJournal: "updateJournal",
      getJournalByTagJournalId: "getJournalByTagJournalId",
    }),
    ...mapActions("tagJournalFile", {
      uploadJournalFile: "uploadJournalFile",
    }),

    getFileIcon(extension) {
      switch (extension) {
        case "txt":
          return "file-document-outline";
        case "csv":
          return "file-delimited-outline";
        case "doc":
        case "docx":
          return "file-word-outline";
        case "xls":
        case "xlsx":
          return "file-excel-outline";
        default:
          return "file-outline";
      }
    },

    // Method to get the appropriate color based on file extension
    getFileIconColor(extension) {
      switch (extension) {
        case "txt":
          return "#4A90E2"; // Blue for text files
        case "csv":
          return "#4CAF50"; // Green for CSV files
        case "doc":
        case "docx":
          return "#0078D4"; // Dark blue for Word files
        case "xls":
        case "xlsx":
          return "#4CAF50"; // Green for Excel files
        case "pdf":
          return "#D32F2F"; // Red for PDFs
        default:
          return "#9E9E9E"; // Gray for unknown file types
      }
    },

    getFileType(filename) {
      const extension = filename.split(".").pop().toLowerCase();
      const imageTypes = ["bmp", "jpeg", "jpg", "gif", "tiff", "png"];
      const documentTypes = ["txt", "lic", "csv", "doc", "docx", "xls", "xlsx"];

      if (imageTypes.includes(extension)) return "image";
      if (extension === "pdf") return "pdf";
      if (documentTypes.includes(extension)) return "document";
      return "other";
    },

    async handleSubmit() {
      await this.addEntry();
    },

    async addEntry() {
      const { comment, files } = this.journalEntry;

      // Proceed only if there is a comment or files to upload
      if (!comment && files.length === 0) return;

      try {
        // Upload files in parallel
        const fileUrls = await Promise.all(
          files.map(async (file) => {
            const uploadPayload = new FormData();
            uploadPayload.append("file", file);
            const fileUrl = await this.uploadJournalFile(uploadPayload);

            if (!fileUrl)
              throw new Error(i18n.t("tag.log.journal.error.uploadFileFailed"));

            return {
              file: fileUrl,
            };
          })
        );

        // Create journal entry payload
        const journalPayload = {
          deveui: this.value,
          comment,
          userId: this.user.userId,
          files: fileUrls,
        };

        // Create the journal entry
        const newJournalEntry = await this.createJournal(journalPayload);
        if (!newJournalEntry)
          throw new Error(i18n.t("tag.log.journal.error.addJournalFailed"));

        // Add to the journal entries and clear the form
        this.journalEntries.unshift(newJournalEntry);
        this.$store.dispatch(
          "alert/success",
          i18n.t("tag.log.journal.success.journalAdded")
        );
        this.clearJournalEntry();
      } catch (error) {
        this.$store.dispatch(
          "alert/error",
          error.message || i18n.t("tag.log.journal.error.general")
        );
      }
    },

    handleFileInput(event) {
      const files = event.target.files;
      for (let file of files) {
        this.addFile(file);
      }
    },

    handleEditFileInput(event) {
      const files = event.target.files;
      for (let file of files) {
        this.addEditFile(file);
      }
    },

    // Method to add a file with extension and type
    addFile(file) {
      this.journalEntry.files.push(file);
      const extension = file.name.split(".").pop().toLowerCase();
      const type = file.type;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewFiles.push({
          src: e.target.result,
          name: file.name,
          extension,
          type,
        });
      };
      reader.readAsDataURL(file);
    },

    addEditFile(file) {
      this.editJournalEntry.files.push(file);
      const extension = file.name.split(".").pop().toLowerCase();
      const type = file.type;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.editPreviewFiles.push({
          src: e.target.result,
          name: file.name,
          extension,
          type,
        });
      };
      reader.readAsDataURL(file);
    },

    removeFile(index) {
      this.journalEntry.files.splice(index, 1);
      this.previewFiles.splice(index, 1);
    },

    removeEditFile(index) {
      this.editJournalEntry.files.splice(index, 1);
      this.editPreviewFiles.splice(index, 1);
    },

    clearJournalEntry() {
      this.journalEntry.comment = "";
      this.journalEntry.files = [];
      this.previewFiles = [];
    },

    handleExpansionChange(entryId) {
      const entry = this.journalEntries.find(
        (journal) => journal.tagJournalId === entryId
      );
      if (entry && entry.files && entry.files.length > 0) {
        this.journalEntryFiles = entry.files.map((file) => ({
          ...file,
          src: `${this.SO_API_BASE_URL}/api/${file.isFile ? "file" : "image"}/${
            file.file
          }`,
          type: this.getFileType(file.file),
          extension: file.file.split(".").pop().toLowerCase(),
        }));
      } else {
        this.journalEntryFiles = [];
      }
    },

    async downloadFile(file) {
      try {
        if (file?.file === undefined) throw new Error("File not found");

        let response = null;

        if (file.isFile) {
          response = await FileRepository.get(file.file);
          if (response.status !== 200)
            throw new Error(
              this.$t("tag.log.journal.error.downloadFileFailed")
            );
        } else {
          response = await ImageRepository.get(file.file);
          if (response.status !== 200)
            throw new Error(
              this.$t("tag.log.journal.error.downloadFileFailed")
            );
        }

        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
        });
        saveAs(blob, file.file);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },

    async openEditDialog(editJournalEntry) {
      // Store the original entry
      this.originalEditEntry = JSON.parse(JSON.stringify(editJournalEntry));

      this.tempEditEntry = JSON.parse(JSON.stringify(editJournalEntry));
      this.editJournalEntry = this.tempEditEntry;

      if (editJournalEntry.files) {
        this.editJournalEntry.files = editJournalEntry.files;
        this.editPreviewFiles = editJournalEntry.files.map((file) => ({
          src: `${this.SO_API_BASE_URL}/api/${file.isFile ? "file" : "image"}/${
            file.file
          }`,
          name: file.file.split("/").pop(),
          extension: file.file.split(".").pop().toLowerCase(),
          type: this.getFileType(file.file),
        }));
      }
      this.editDialog = true;
    },

    closeEditDialog() {
      // Restore the original entry state
      if (this.originalEditEntry) {
        const index = this.journalEntries.findIndex(
          (entry) => entry.tagJournalId === this.originalEditEntry.tagJournalId
        );
        if (index !== -1) {
          this.$set(this.journalEntries, index, this.originalEditEntry);
        }

        // Restore journalEntryFiles if the current entry is expanded
        if (this.journalEntryFiles.length > 0) {
          this.journalEntryFiles = this.originalEditEntry.files.map((file) => ({
            ...file,
            src: `${this.SO_API_BASE_URL}/api/${
              file.isFile ? "file" : "image"
            }/${file.file}`,
            type: this.getFileType(file.file),
            extension: file.file.split(".").pop().toLowerCase(),
          }));
        }
      }

      this.editDialog = false;
      this.editJournalEntry = { comment: "", files: [] };
      this.editPreviewFiles = [];
      this.currentEntryId = null;
      this.originalEditEntry = null; // Clear the original entry
    },

    async updateEntry() {
      const { comment, files, tagJournalId } = this.editJournalEntry;

      if (!comment && files.length === 0) {
        this.$store.dispatch(
          "alert/error",
          i18n.t("tag.log.journal.error.noChanges")
        );
        return;
      }

      try {
        // Filter new files and upload them
        const newFiles = files.filter(
          (file) => file.tagJournalFileId === undefined
        );
        const fileUrls = await Promise.all(
          newFiles.map(async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            const fileUrl = await this.uploadJournalFile(formData);
            if (!fileUrl)
              throw new Error(i18n.t("tag.log.journal.error.uploadFileFailed"));
            return {
              file: fileUrl,
            };
          })
        );

        // Combine new and existing files
        const allFiles = [
          ...fileUrls,
          ...files.filter((file) => file.tagJournalFileId !== undefined),
        ];

        // Prepare and send journal entry payload
        const journalPayload = {
          ...this.editJournalEntry,
          deveui: this.value,
          userId: this.user.userId,
          files: allFiles,
          fileCount: allFiles.length,
        };

        // Send update request
        const updatedJournal = await this.updateJournal({
          tagJournalId,
          payload: journalPayload,
        });

        // Update journal entry in local state and move to the top
        const index = this.journalEntries.findIndex(
          (entry) => entry.tagJournalId === tagJournalId
        );
        if (index !== -1) {
          this.journalEntries[index] = updatedJournal;
          this.journalEntries.unshift(this.journalEntries.splice(index, 1)[0]);
        }

        this.handleExpansionChange(tagJournalId);

        this.$store.dispatch(
          "alert/success",
          i18n.t("tag.log.journal.success.journalUpdated")
        );
        this.originalEditEntry = null;
        this.closeEditDialog();
      } catch (error) {
        this.$store.dispatch(
          "alert/error",
          error.message || i18n.t("tag.log.journal.error.general")
        );
      }
    },

    // Method for confirming the delete action
    confirmDelete(entry) {
      this.entryToDelete = entry;
      this.dialog = true;
    },

    // Method for deleting the entry
    async deleteEntry() {
      if (this.entryToDelete) {
        const success = await this.deleteJournal(
          this.entryToDelete.tagJournalId
        );
        if (success) {
          const index = this.journalEntries.findIndex(
            (e) => e.tagJournalId === this.entryToDelete.tagJournalId
          );
          if (index !== -1) {
            this.journalEntries.splice(index, 1);
            this.$store.dispatch(
              "alert/success",
              i18n.t("tag.log.journal.success.journalDeleted")
            );
          }
        } else {
          // Handle the error if deletion was not successful
          this.$store.dispatch(
            "alert/error",
            i18n.t("tag.log.journal.error.deleteJournalFail")
          );
        }

        // Close the dialog and clear the entryToDelete
        this.dialog = false;
        this.entryToDelete = null;
      }
    },
  },

  async created() {
    // Fetch journals and process them directly
    this.journalEntries = await tagJournalRepository.getJournalsForTag(
      this.value,
      "desc"
    );
  },
};
</script>

<style scoped>
.file-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.file-icon {
  font-size: 48px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.file-label {
  font-size: 12px;
  text-align: center;
}

.file-download-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(190, 177, 177, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.file-container:hover .file-download-btn {
  display: flex;
}

.file-download-btn:hover {
  background-color: green;
}

.custom-border1 {
  border-style: solid;
  border-width: 1px 1px 1px 1px; /* Top, right, and left borders are 1px, bottom border is 0 */
  border-color: lightgrey; /* Light grey color for the border */
  border-radius: 0; /* Remove border radius if you don't want rounded corners */
}

.rotate-up {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate-down {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.custom-chip {
  width: 90px;
  justify-content: center;
  align-items: center;
}

.theme--light {
  .v-expansion-panel {
    background-color: #ffffff !important;
  }
}

.theme--dark {
  .v-expansion-panel {
    background-color: #383838 !important;
  }
}
</style>
