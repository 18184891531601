<template>
  <v-card fluid class="pa-2 ma-0 event-hub-container">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          class="d-flex align-center justify-start"
        >
          <v-btn text @click="clearEvents">{{ $t("common.clear") }}</v-btn>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6">
          <v-pagination
            v-model="page"
            :length="length"
            v-if="events.length > 0"
          />
        </v-col>
      </v-row>
    </v-container>
    <event-hub-content :value="ev" v-for="(ev, i) in eventsPaged" :key="i" />
    <v-pagination v-model="page" :length="length" v-if="events.length > 0" />

    <div v-if="events.length <= 0">
      <v-progress-circular indeterminate color="primary" class="pl-2" />
      {{ $t("eventhub.waiting") }}
    </div>
  </v-card>
</template>

<script>
import eventHubMixin from "../../_helpers/eventHubMixin";
import eventRepository from "../../api/repositories/eventRepository";
import EventHubContent from "./EventHubContent.vue";

export default {
  components: { EventHubContent },
  name: "EventHub",

  mixins: [eventHubMixin],

  props: {
    value: {
      default: undefined,
    },
  },

  data() {
    return {
      page: 1,
      pageSize: 25,
      events: [],
    };
  },

  methods: {
    event(e) {
      this.events.unshift(e);

      if (this.length > 10) this.events.pop();
    },

    clearEvents() {
      this.events = [];
      this.page = 1;
    },
  },

  computed: {
    length() {
      return Math.ceil(this.events.length / this.pageSize);
    },

    eventsPaged() {
      return this.events.slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
    },
  },

  async created() {
    await this.initHub();
    this.attach(this.value);

    var result = await eventRepository.getEventsForDeveui(this.value);
    for (var r of result) this.events.push(r);
  },
};
</script>

<style>
.event-hub-container {
  max-height: 100%;
  overflow-y: scroll;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
</style>
