<template>
  <v-row justify="center" class="mt-2">
    <v-col cols="12" sm="12" md="12">
      <v-card class="elevation-12 pa-4 mb-1">
        <v-select
          v-model="selectedOption"
          :items="options"
          :label="$t('tag.log.picker')"
          outlined
        ></v-select>
      </v-card>

      <!-- Journals -->
      <v-card v-if="selectedOption === 1" class="elevation-12 pa-4">
        <tag-journal v-if="value" v-model="value" />
      </v-card>

      <!-- Events -->
      <v-card v-else-if="selectedOption === 2">
        <event-hub v-if="value" v-model="value" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import EventHub from "@/components/events/EventHub.vue";
import TagJournal from "@/components/tagJournal/TagJournal.vue";
import { mapGetters } from "vuex";

export default {
  name: "TagLog",

  props: {
    value: {
      default: undefined,
    },
  },

  components: {
    EventHub,
    TagJournal,
  },

  computed: {
    ...mapGetters("users", ["companyId"]),
  },

  data() {
    return {
      selectedOption: 1, // Default key
      options: [
        { text: this.$t("tag.log.journal.title"), value: 1 },
        { text: this.$t("tag.log.events"), value: 2 },
      ],
    };
  },
};
</script>
