import Repository from "../Repository";
import { apiConfig, getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/image";

export default {
  get(file) {
    const config = {
      headers: apiConfig()?.headers,
      responseType: "blob",
    };

    return Repository.get(`${resource}/${file}`, config);
  },
  postImage(user, image) {
    let config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}`, image, config);
  },
};
