<template>
  <div>
    <v-card-text>
      <div v-if="localValue.nodeType != 4">
        <broker-selector
          v-if="localValue?.mqttMeta != null"
          v-model="localValue.mqttMeta.brokerId"
        />
        <mqtt-meta-form
          v-model="localValue.mqttMeta"
          :broker="localValue.mqttMeta.brokerId"
          :deveui="localValue.deveui"
          :node-type="localValue.nodeType"
        />
      </div>
      <div v-else>Mqtt Publishing not supported on Sparkplug Nodes</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="updateMeta" color="primary">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import BrokerSelector from "../mqtt/BrokerSelector.vue";
import MqttMetaForm from "../mqtt/MqttMetaForm.vue";

export default {
  components: { BrokerSelector, MqttMetaForm },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  model: { prop: "value" },

  data() {
    return {
      mqttMetaCreate: false,
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("mqtt", ["updateMqttMeta", "createMqttMeta"]),

    async updateMeta() {
      if (!this.mqttMetaCreate)
        await this.updateMqttMeta({
          mqttMetaId: this.localValue.mqttMeta.mqttMetaId,
          brokerId: this.localValue.mqttMeta.brokerId,
          payload: this.localValue.mqttMeta,
        });
      else {
        let res = await this.createMqttMeta({
          brokerId: this.localValue.mqttMeta.brokerId,
          deveui: this.localValue.deveui,
          payload: this.localValue.mqttMeta,
        });
        if (res != null) this.localValue.mqttMeta = res;
      }
    },
  },

  watch: {
    value: {
      handler(val) {
        this.mqttMetaCreate = val?.mqttMeta?.mqttMetaId == undefined;
      },
      deep: true,
    },
  },
};
</script>
